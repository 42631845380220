import React from 'react';

const Mark = props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      viewBox="0 0 99.9 95.5"
    >
      <title>Structure Plus Logo</title>
      <path
        className="mark-left"
        d="M0 41.4v18l15.6 9.1v18l15.7 9v-18l15.6 9v-18l-15.6-9.1v-18l-15.7-9v18z"
      />
      <path
        className="mark-right"
        d="M99.9 41.4v18l-15.6 9.1v18l-15.7 9v-18l-15.6 9v-18l15.6-9.1v-18l15.7-9v18z"
      />
      <path
        className="mark-top"
        d="M81.2 9L65.6 0 50 9 34.3 0 18.7 9l15.6 9-15.6 9.1 15.6 9 15.7-9 15.6 9 15.6-9L65.6 18z"
      />
    </svg>
  );

export default Mark;
