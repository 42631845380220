import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Nav = props => (
  <ul className={props.className}>
    <li>
      <GatsbyLink to="/" activeClassName="active">
        Home
      </GatsbyLink>
    </li>
    <li>
      <GatsbyLink to="/about" activeClassName="active">
        About
      </GatsbyLink>
    </li>
    <li>
      <GatsbyLink to="/services" activeClassName="active">
        Services
      </GatsbyLink>
    </li>
    <li>
      <GatsbyLink to="/resources" activeClassName="active">
        Resources
      </GatsbyLink>
    </li>
    <li>
      <GatsbyLink to="/careers" activeClassName="active">
        Careers
      </GatsbyLink>
    </li>

    <li className="contact-Gatsbylink">
      <GatsbyLink to="/contact" activeClassName="active">
        Contact
      </GatsbyLink>
    </li>
  </ul>
);

export default Nav;
