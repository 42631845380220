module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Structure Plus', // Navigation and Site Title
  siteTitleAlt: 'STR+', // Alternative Site title for SEO
  siteUrl: 'https://www.structurepluswv.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '../src/images/favicon.png', // Used for SEO and manifest
  siteDescription:
    'We offer a full range of services within the construction/restoration industry, from general construction with superior workmanship, to highly specialized mitigation and technical services.',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@user', // Twitter Username
  ogSiteName: 'mmd', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: '#0072bc',
  backgroundColor: '#ffffff',
};
