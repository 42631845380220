import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import config from "../../config/website";

import Mark from "./Mark";

const Footer = ({ data }) => {
  const d = new Date();
  const n = d.getFullYear();

  return (
    <footer className="bg-black text-grey">
      <div className="container">
        <div className="flex flex-wrap py-20 border-b border-grey-darkest md:-mx-6">
          <div className="w-full uppercase md:w-1/5 md:px-6">
            <Mark className="w-16 mb-4" />
          </div>
          <div className="w-full md:w-1/5 md:px-6">
            <p className="text-white font-bold">
              Mitigation and Emergency Services
            </p>
            <ul className="list-reset m-0 p-0 flex flex-wrap mb-4">
              {data.mitigation.edges.map(({ node }) => (
                <li key={node.id} className="w-full" style={{ height: "40px" }}>
                  <Link
                    to={`/services/${node.uid}`}
                    className="no-underline block text-white"
                  >
                    {node.data.title.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/5 md:px-6">
            <p className="text-white font-bold">
              Remodeling and Construction Services
            </p>
            <ul className="list-reset m-0 p-0 flex flex-wrap mb-4">
              {data.remodeling.edges.map(({ node }) => (
                <li
                  key={node.id}
                  className="w-full block"
                  style={{ height: "40px" }}
                >
                  <Link
                    to={`/services/${node.uid}`}
                    className="no-underline text-white"
                  >
                    {node.data.title.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/5 md:px-6">
            <ul className="list-reset m-0 p-0 flex flex-wrap mb-4">
              <li className="w-full block" style={{ height: "40px" }}>
                <Link to="/about" className="no-underline text-white">
                  About
                </Link>
              </li>
              <li className="w-full block" style={{ height: "40px" }}>
                <Link to="/resources" className="no-underline text-white">
                  Resources
                </Link>
              </li>
              <li className="w-full block" style={{ height: "40px" }}>
                <Link to="/careers" className="no-underline text-white">
                  Careers
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/5 md:px-6">
            <ul className="list-reset m-0 p-0 flex flex-wrap">
              <li className="border border-white border-2 py-2 px-6 rounded-full hover:bg-white hover:text-black">
                <Link to="/contact" className="no-underline block">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap py-10">
          <div className="w-full text-center text-xs text-grey">
            <p>
              &copy; {n} {config.siteTitle}. Website Design + Development:{" "}
              <a
                href="https://mindmergedesign.com"
                target="_blank"
                rel="noopener"
                className="text-grey no-underline"
              >
                Mind Merge Design
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query footerInfo {
        prismicServices {
          uid
          id
        }
        remodeling: allPrismicService(
          filter: {
            data: { service_type: { eq: "Remodeling and Construction" } }
          }
          sort: { fields: data___title___text, order: ASC }
        ) {
          edges {
            node {
              ...footerServiceFragment
            }
          }
        }
        mitigation: allPrismicService(
          filter: {
            data: { service_type: { eq: "Mitigation and Emergency Services" } }
          }
          sort: { fields: data___title___text, order: ASC }
        ) {
          edges {
            node {
              ...footerServiceFragment
            }
          }
        }
      }

      fragment footerServiceFragment on PrismicService {
        uid
        id
        data {
          title {
            text
          }
          service_type
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);
