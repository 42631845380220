import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import './styles/layout.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        prismicGlobal {
          id
          data {
            address {
              html
              text
            }
            email_address {
              html
              text
            }
            phone_number {
              text
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet title={data.site.siteMetadata.title}>
          <html lang="en" />
        </Helmet>
        <Header phone_number={data.prismicGlobal.data.phone_number.text} />
        {children}
        <Footer
          address={data.prismicGlobal.data.address.text}
          phone_number={data.prismicGlobal.data.phone_number.text}
        />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
