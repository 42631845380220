import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Nav from './Nav';
import Hamburger from './Hamburger';
import Logo from './Logo';

const Header = ({ phone_number }) => (
  <header id="header">
    <div className="text-right border-t-2 border-black">
      <div className="container">
        <p className="text-xs py-1 px-2 mb-0 bg-black inline-block text-white rounded-b-lg">Call: {phone_number}</p>
      </div>
    </div>
    <div className="navbar shadow overflow-hidden relative z-50 bg-white md:flex md:flex-wrap md:items-center">
      <div className="container">
        <div className="flex flex-wrap md:flex-no-wrap items-center justify-between">
          <div className="flex justify-center header-brand">
            <GatsbyLink to="/" aria-label="Structure Plus Logo">
              <Logo className="logo-header" />
            </GatsbyLink>
          </div>
          <Hamburger />

          <div className="md:self-center w-full md:flex-grow md:flex md:items-center md:w-auto md:justify-end">
            <Nav className="main-nav md:flex font-sans text-sm mt-4 md:mt-0" />
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
